
import { Options, Vue } from "vue-class-component";
import Modal from "@/components/Modal.vue";
import DetailsRow from "@/components/DetailsRow.vue";
import DatePicker from "@/components/Form/DatePicker.vue";
import FileUpload from "@/components/Form/FileUpload.vue";
import LuSpinner from "@/components/Lu/LuSpinner.vue";
import IdHelper from "@/helpers/id-helper";
import StringHelper from "@/helpers/string-helper";
import UploadService from "@/services/upload-service";
import { IUploadFile } from "@/interfaces/IUploadFile";

@Options({
  components: {
    Modal,
    DetailsRow,
    DatePicker,
    FileUpload,
    LuSpinner,
  },
  data: () => ({
    /*
     * The notes object which is both retrieved and sent
     */
    notes: null,
    /**
     * If the data is still loading used to show spinner
     */
    isLoading: false,
    /*
     * The time format for the date picker to use
     */
    timeFormat: "HH:mm",
    /*
     * The date format for the date picker to use
     */
    dateFormat: "yyyy-MM-dd",
    /*
     * Disables selection of Saturdyas and Sundays in the date picker
     */
    disabledWeekDays: [0, 6],
    /*
     * Populated by the date only date picker
     */
    notesDate: null,
    /*
     * Populated by the time only date picker
     */
    notesTime: null,
    /*
     * Passed to the file upload component to limit file types that are allowed to be uploaded
     */
    permittedFileTypes: "doc, docx, odt, pdf, rtf, txt, md",
    /*
     * Passed to the file upload component to limit the file size that is allowed to be uploaded
     */
    maxSizeInMb: 20,
    /*
     * The file to be uploaded
     * (emitted from the file upload component)
     */
    file: {},
    /*
     * Used to disable the call to the upload button
     * (emitted from the file upload component)
     */
    fileIsSelected: false,
    /*
     * Used to delay the call to the upload service
     * (emitted from the file upload component)
     */
    fileIsReady: false,
  }),
  computed: {
    /*
     * The course title in the selected language
     */
    courseTitle(): string {
      return this.$i18n.locale === "sv"
        ? this.notes?.courseTitleSv
        : this.notes?.courseTitleEn;
    },
  },
  methods: {
    /**
     * Fetches the note-taker note details including the note-taker's name
     * and course code, term, and title
     */
    getNoteTakerNoteDetails(): void {
      this.isLoading = true;

      const luEduPersonPrimaryId = IdHelper.getLuEduPersonPrimaryId(
        this.$route.query.lucatid
      );

      const courseOccurrenceId = this.$route.params.id;

      UploadService.getNoteTakerNotes(luEduPersonPrimaryId, courseOccurrenceId)
        .then((notes) => {
          this.notes = notes;
          this.isLoading = false;
        })
        .catch((statusCode) => {
          if (statusCode === 404) {
            // if the current user is not an active note-taker for the course, the backend will return a 404 response
            return this.$router.push({
              name: "Unauthorized",
            });
          } else {
            alert("Something went wrong" + statusCode);
          }
        });
    },
    /*
     * Disables selection of future dates in the date picker
     */
    disabledDates(date: Date): boolean {
      return date > new Date();
    },
    /*
     * Navigates to the student home page
     */
    goToNoteTakingRequest() {
      const lucatid = this.$route.query.lucatid;

      const route = {
        name: "NoteTakingRequest",
        query: {},
      };

      if (lucatid) {
        route.query = { lucatid: lucatid };
      }

      this.$router.push(route);
    },
    /*
     * Uploads the note-taker's note
     */
    onShareButtonClicked(): void {
      this.isLoading = true;

      this.$refs.fileUpload.emitFile();
      while (!this.fileIsReady);

      this.populateNoteTakerNotes();

      UploadService.uploadNotes(this.notes).then((result: boolean) => {
        this.isLoading = false;

        if (result) {
          this.$refs["success-modal"].open();
        } else {
          this.$refs["error-modal"].open();
        }
      });
    },
    /*
     * Populates the note-taker notes object to be uploaded
     */
    populateNoteTakerNotes(): void {
      const file: IUploadFile = this.file;

      this.notes.date = this.notesDate.toISOString().substring(0, 10);
      this.notes.time = this.formatTime();
      this.notes.file = {
        name: file.name,
        size: file.size,
        extension: file.extension,
        binaryData: file.binaryData?.toString(),
      };
    },
    /*
     * Formats the notes time
     */
    formatTime(): string {
      const time = this.notesTime;
      const hours = StringHelper.addLeadingZero(time.hours);
      const minutes = StringHelper.addLeadingZero(time.minutes);
      return `${hours}${minutes}`;
    },
  },
  async mounted() {
    this.getNoteTakerNoteDetails();
  },
})
export default class ShareNotes extends Vue {}
