import { NoteTakerNotes } from "@/interfaces/api";
import BaseService from "@/services/base-service";

class UploadService extends BaseService {
  /**
   * Fetches the note-taker notes with the specified note-taker id and course id
   *
   * @param {string} luEduPersonPrimaryId - The lucat id of the note-taker
   * * @param {number} courseOccurrenceId - The id of the course occurence
   * @returns {Promise<NoteTakerNotes | undefined>} A Promise with the NoteTakerNotes
   */
  getNoteTakerNotes(
    luEduPersonPrimaryId: string,
    courseOccurrenceId: number
  ): Promise<NoteTakerNotes | undefined> {
    const endpointController = "Upload";
    const parameterName = "luEduPersonPrimaryId";
    const parameterArgument = luEduPersonPrimaryId;
    const parameterName2 = "courseOccurrenceId";
    const parameterArgument2 = courseOccurrenceId;
    const queryString = `${parameterName}=${parameterArgument}&${parameterName2}=${parameterArgument2}`;
    const getNoteTakerNotes = `${this.baseApiUrl}api/${endpointController}?${queryString}`;
    return this.fetch<NoteTakerNotes>(getNoteTakerNotes);
  }

  /**
   * Uploads the specified note-taker notes
   *
   * @param {NoteTakerNotes>} notes - The note-taker notes
   * @returns {Promise<boolean>} A Promise with a boolean representing the success of the operation
   */
  uploadNotes(notes: NoteTakerNotes): Promise<boolean> {
    const endpointController = "Upload";
    const acceptOffer = `${this.baseApiUrl}api/${endpointController}`;
    return this.fetch<boolean>(acceptOffer, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notes),
    });
  }
}

export default new UploadService();
